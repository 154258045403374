// Login User
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

// User Signup
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

//Logout Request
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

// User Forgot Password
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

// Verify User Link
export const VERIFY_USER_REQUEST = "VERIFY_USER_REQUEST";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAILURE = "VERIFY_USER_FAILURE";

// User Reset Password
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

// User Social Login
export const SOCIAL_LOGIN_REQUEST = "SOCIAL_LOGIN_REQUEST";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAILURE = "SOCIAL_LOGIN_FAILURE";

// Verify User Email
export const VERIFY_USER_EMAIL_REQUEST = "VERIFY_USER_EMAIL_REQUEST";
export const VERIFY_USER_EMAIL_SUCCESS = "VERIFY_USER_EMAIL_SUCCESS";
export const VERIFY_USER_EMAIL_FAILURE = "VERIFY_USER_EMAIL_FAILURE";

// Update User Profile
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

// Change Password
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

// Get User Participants
export const GET_USER_PARTICIPANTS_REQUEST = "GET_USER_PARTICIPANTS_REQUEST";
export const GET_USER_PARTICIPANTS_SUCCESS = "GET_USER_PARTICIPANTS_SUCCESS";
export const GET_USER_PARTICIPANTS_FAILURE = "GET_USER_PARTICIPANTS_FAILURE";

// Create user contract
export const CREATE_CONTRACT_REQUEST = "CREATE_CONTRACT_REQUEST";
export const CREATE_CONTRACT_SUCCESS = "CREATE_CONTRACT_SUCCESS";
export const CREATE_CONTRACT_FAILURE = "CREATE_CONTRACT_FAILURE";

// Get All Contracts list
export const CONTRACT_LIST_REQUEST = "CONTRACT_LIST_REQUEST";
export const CONTRACT_LIST_SUCCESS = "CONTRACT_LIST_SUCCESS";
export const CONTRACT_LIST_FAILURE = "CONTRACT_LIST_FAILURE";

// Add Account Request
export const ADD_ACCOUNT_REQUEST = "ADD_ACCOUNT_REQUEST";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE";

// Update Account Request
export const UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE";

// Delete Account Request
export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

// Add Contract Address
export const CONTRACT_ADDRESS_REQUEST = "CONTRACT_ADDRESS_REQUEST";
export const CONTRACT_ADDRESS_SUCCESS = "CONTRACT_ADDRESS_SUCCESS";
export const CONTRACT_ADDRESS_FAILURE = "CONTRACT_ADDRESS_FAILURE";

// Manual sync Contract Address
export const SYNC_CONTRACT_ADDRESS_REQUEST = "SYNC_CONTRACT_ADDRESS_REQUEST";
export const SYNC_CONTRACT_ADDRESS_SUCCESS = "SYNC_CONTRACT_ADDRESS_SUCCESS";
export const SYNC_CONTRACT_ADDRESS_FAILURE = "SYNC_CONTRACT_ADDRESS_FAILURE";
