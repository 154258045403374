import React, { Fragment, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import DefaultLayout from "../pages/DefaultLayout/defaultLayout";

type StateProps = {};

type Props = {
  history?: any;
} & StateProps;

const Routes = ({ history }: Props): JSX.Element => {
  return (
    <Fragment>
      <Suspense fallback={"Loading..."}>
        <Switch>
          <Route
            exact={false}
            path={"/"}
            render={(routesProps) => {
              return <DefaultLayout {...routesProps} {...history} />;
            }}
          />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default Routes;
