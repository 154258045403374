import { all, call, put, select, takeLatest } from "redux-saga/effects";
import * as ActionTypes from "../Actions/actionTypes";
import { ReduxState } from "../Reducers";
import { ILoginResponse } from "../../utils/Interfaces/authInterfaces";
import {
  IAccountFormValues,
  IContractFormValues,
} from "../../utils/Interfaces/contractInterface";
import {
  addAccountApi,
  createContractApi,
  deleteAccountApi,
  getContractListApi,
  getParticipantsApi,
  updateAccountApi,
  syncContractAddressApi,
} from "../../apis/constractApis";
import {
  addAccountFailure,
  addAccountSuccess,
  contractListFailure,
  contractListSuccess,
  createContractFailure,
  createContractSuccess,
  deleteAccountFailure,
  deleteAccountSuccess,
  getParticipantsFailure,
  getParticipantsSuccess,
  syncContractAddressFailure,
  syncContractAddressSuccess,
  updateAccountFailure,
  updateAccountSuccess,
} from "../Actions/contract";
import history from "../../utils/history";

type Params = {
  contractData: IContractFormValues;
  accountData: IAccountFormValues;
  participantId: string;
  contractId: string;
  type: string;
};

function* createContract({ contractData }: Params) {
  try {
    const userInfo: ILoginResponse = yield select(
      (state: ReduxState) => state.auth.userInfo
    );
    const response: Object = yield call(
      createContractApi,
      contractData,
      userInfo?.token,
      userInfo?.id
    );
    yield put(createContractSuccess(response));
  } catch (error: any) {
    const { response } = error;
    if (
      response?.data.message === "Unauthorized" ||
      response?.data.message === "Error: Incorrect User"
    ) {
      localStorage.removeItem("token");
      history.push("/login");
    }
    yield put(createContractFailure(response?.data.message));
  }
}

function* getParticipants() {
  try {
    const userInfo: ILoginResponse = yield select(
      (state: ReduxState) => state.auth.userInfo
    );
    const response: { data: Object } = yield call(
      getParticipantsApi,
      userInfo?.token,
      userInfo?.id
    );
    yield put(getParticipantsSuccess(response.data));
  } catch (error: any) {
    const { response } = error;
    if (
      response?.data.message === "Unauthorized" ||
      response?.data.message === "Error: Incorrect User"
    ) {
      localStorage.removeItem("token");
      history.push("/login");
    }
    yield put(getParticipantsFailure(response?.data.message));
  }
}

function* getContractList() {
  try {
    const userInfo: ILoginResponse = yield select(
      (state: ReduxState) => state.auth.userInfo
    );
    const response: { data: Object } = yield call(
      getContractListApi,
      userInfo?.token,
      userInfo?.id
    );
    yield put(contractListSuccess(response.data));
  } catch (error: any) {
    const { response } = error;
    console.log("responseresponseresponse", response);
    if (
      response?.data.message === "Unauthorized" ||
      response?.data.message === "Error: Incorrect User"
    ) {
      localStorage.removeItem("token");
      history.push("/login");
    }
    yield put(contractListFailure(response?.data.message));
  }
}

function* addAccount({ accountData }: Params) {
  try {
    const userInfo: ILoginResponse = yield select(
      (state: ReduxState) => state.auth.userInfo
    );
    const response: { data: Object } = yield call(
      addAccountApi,
      accountData,
      userInfo?.token,
      userInfo?.id
    );
    yield put(addAccountSuccess(response.data));
  } catch (error: any) {
    const { response } = error;
    if (
      response?.data.message === "Unauthorized" ||
      response?.data.message === "Error: Incorrect User"
    ) {
      localStorage.removeItem("token");
      history.push("/login");
    }
    yield put(addAccountFailure(response?.data.message));
  }
}

function* updateAccount({ accountData }: Params) {
  try {
    const userInfo: ILoginResponse = yield select(
      (state: ReduxState) => state.auth.userInfo
    );
    const response: { data: Object } = yield call(
      updateAccountApi,
      accountData,
      userInfo?.token,
      userInfo?.id
    );
    yield put(updateAccountSuccess(response.data));
  } catch (error: any) {
    const { response } = error;
    if (
      response?.data.message === "Unauthorized" ||
      response?.data.message === "Error: Incorrect User"
    ) {
      localStorage.removeItem("token");
      history.push("/login");
    }
    yield put(updateAccountFailure(response?.data.message));
  }
}

function* deleteAccount({ participantId }: Params) {
  try {
    const userInfo: ILoginResponse = yield select(
      (state: ReduxState) => state.auth.userInfo
    );
    yield call(deleteAccountApi, participantId, userInfo?.token, userInfo?.id);
    yield put(deleteAccountSuccess({ id: participantId }));
  } catch (error: any) {
    const { response } = error;
    if (
      response?.data.message === "Unauthorized" ||
      response?.data.message === "Error: Incorrect User"
    ) {
      localStorage.removeItem("token");
      history.push("/login");
    }
    yield put(deleteAccountFailure(response?.data.message));
  }
}

function* syncContractAddress({ contractId }: Params) {
  try {
    const userInfo: ILoginResponse = yield select(
      (state: ReduxState) => state.auth.userInfo
    );
    const response: { data: object } = yield call(
      syncContractAddressApi,
      contractId,
      userInfo?.token,
      userInfo?.id
    );
    yield put(syncContractAddressSuccess({...response.data, id: contractId}));
  } catch (error: any) {
    const { response } = error;
    if (
      response?.data.message === "Unauthorized" ||
      response?.data.message === "Error: Incorrect User"
    ) {
      localStorage.removeItem("token");
      history.push("/login");
    }
    yield put(syncContractAddressFailure(response?.data.message));
  }
}

function* watchGetRequest() {
  yield takeLatest(ActionTypes.CREATE_CONTRACT_REQUEST, createContract);
  yield takeLatest(ActionTypes.GET_USER_PARTICIPANTS_REQUEST, getParticipants);
  yield takeLatest(ActionTypes.CONTRACT_LIST_REQUEST, getContractList);
  yield takeLatest(ActionTypes.ADD_ACCOUNT_REQUEST, addAccount);
  yield takeLatest(ActionTypes.UPDATE_ACCOUNT_REQUEST, updateAccount);
  yield takeLatest(ActionTypes.DELETE_ACCOUNT_REQUEST, deleteAccount);
  yield takeLatest(
    ActionTypes.SYNC_CONTRACT_ADDRESS_REQUEST,
    syncContractAddress
  );
}

export default function* sagas() {
  yield all([watchGetRequest()]);
}
