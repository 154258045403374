import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  loginSuccess,
  loginFailure,
  signupSuccess,
  signupFailure,
  logoutSuccess,
  logoutFailure,
  forgotPasswordSuccess,
  fortgotPasswordFailure,
  verifyUserLinkSuccess,
  verifyUserLinkFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  socialLoginSuccess,
  socialLoginFailure,
  verifyUserEmailSuccess,
  verifyUserEmailFailure,
} from "../Actions/authentication";

import * as ActionTypes from "../Actions/actionTypes";
import {
  ILoginFormValues,
  ISignupFormValues,
  ISocialLoginValues,
} from "../../utils/Interfaces/authInterfaces";
import {
  signUpRequest,
  userLogin,
  forgotPasswordApi,
  verifyLinkApi,
  resetPasswordApi,
  socialLoginApi,
  verifyEmailApi,
} from "../../apis/authApis";

type Params = {
  data: ILoginFormValues | any;
  socialData: ISocialLoginValues;
  signupData: ISignupFormValues;
  type: string;
  email: string;
  token: string;
  password: string | number;
};

function* login({ data }: Params) {
  try {
    const loginResponse: { data: any } = yield call(userLogin, data);
    yield put(loginSuccess(loginResponse.data));
  } catch (error: any) {
    const { response } = error;
    yield put(loginFailure(response?.data.message));
  }
}

function* signUp({ signupData }: Params) {
  try {
    const signUpResponse: { data: any } = yield call(signUpRequest, signupData);
    yield put(signupSuccess(signUpResponse.data));
  } catch (error: any) {
    const { response } = error;
    yield put(signupFailure(response?.data.message));
  }
}

function* logout() {
  try {
    yield put(logoutSuccess());
  } catch (error: any) {
    yield put(logoutFailure(error?.message));
  }
}

function* forgotPassword({ email }: Params) {
  try {
    const response: Object = yield call(forgotPasswordApi, email);
    yield put(forgotPasswordSuccess(response));
  } catch (error: any) {
    const { response } = error;
    yield put(fortgotPasswordFailure(response?.data.message));
  }
}
function* verifyUserLink({ token }: Params) {
  try {
    const response: Object = yield call(verifyLinkApi, token);
    yield put(verifyUserLinkSuccess(response));
  } catch (error: any) {
    const { response } = error;
    yield put(verifyUserLinkFailure(response?.data.message));
  }
}
function* resetPassword({ token, password }: Params) {
  try {
    const response: Object = yield call(resetPasswordApi, token, password);
    yield put(resetPasswordSuccess(response));
  } catch (error: any) {
    const { response } = error;
    yield put(resetPasswordFailure(response?.data.message));
  }
}

function* socialLogin({ socialData }: Params) {
  try {
    const response: { data: Object } = yield call(socialLoginApi, socialData);
    const { data } = response;
    yield put(socialLoginSuccess(data));
  } catch (error: any) {
    const { response } = error;
    yield put(socialLoginFailure(response?.data.message));
  }
}
function* verifyUserEmail({ token }: Params) {
  try {
    const response: Object = yield call(verifyEmailApi, token);
    yield put(verifyUserEmailSuccess(response));
  } catch (error: any) {
    const { response } = error;
    yield put(verifyUserEmailFailure(response?.data.message));
  }
}
function* watchGetRequest() {
  yield takeLatest(ActionTypes.USER_LOGIN_REQUEST, login);
  yield takeLatest(ActionTypes.SIGNUP_REQUEST, signUp);
  yield takeLatest(ActionTypes.LOGOUT_REQUEST, logout);
  yield takeLatest(ActionTypes.FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(ActionTypes.VERIFY_USER_REQUEST, verifyUserLink);
  yield takeLatest(ActionTypes.RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(ActionTypes.SOCIAL_LOGIN_REQUEST, socialLogin);
  yield takeLatest(ActionTypes.VERIFY_USER_EMAIL_REQUEST, verifyUserEmail);
}

export default function* sagas() {
  yield all([watchGetRequest()]);
}
