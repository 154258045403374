import { CombinedState, combineReducers } from "redux";
import authReducer from "./authReducer";
import { State as AuthState, Action as AuthAction } from "./authReducer";
import dashboardReducer from "./dashboardReducer";
import {
  State as DashboardState,
  Action as DashboardAction,
} from "./dashboardReducer";
import contractReducer from "./contractReducer";
import {
  State as ContractState,
  Action as ContractAction,
} from "./contractReducer";
const appReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  contract: contractReducer,
});

export type ReduxState = {
  auth: AuthState;
  dashboard: DashboardState;
  contract: ContractState;
};

export type Action = AuthAction & DashboardAction & ContractAction;

const rootReducer = (state: CombinedState<ReduxState>, action: Action) => {
  if (action.type === "RESET_ALL_DATA") {
    state = {
      auth: state.auth,
      dashboard: state.dashboard,
      contract: state.contract,
    };
  }
  return appReducer(state as any, action);
};

export default rootReducer;
