import React, { Fragment } from "react";
import sitelogo from "../assets/images/logo.svg"; 
import logoff from "../assets/images/logoff.svg";
import profile from "../assets/images/profile.svg";
import contactIcon from "../assets/images/contacts.svg"; 

type Props = {
  history: any;
  isLoggedin: boolean;
  getInitials: string;
  handleLogout: () => void;
  pathName: string;
};

const HeaderComponent = ({
  history,
  isLoggedin,
  getInitials,
  handleLogout,
  pathName,
}: Props) => {
  return (
    // Header Start
    <nav className="navbar navbar-expand-lg navbar-dark site-header fixed-top">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6 col-md-3 order-1 logoWrapr">
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="top"></span>
              <span className="mid"></span>
              <span className="btm"></span>
            </button>
            <span
              className="navbar-brand cursor-pointer"
              onClick={() => history.push("/")}
            >
              <img src={sitelogo} alt="logo" className="img-fluid" />
            </span>
          </div>
          <div
            className="col-md-7 col-lg-auto collapse navbar-collapse order-3 order-lg-2"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav primary__menu ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <span
                  className={`nav-link ${
                    pathName === "/" ? "active" : ""
                  } cursor-pointer`}
                  onClick={() => history.push("/")}
                >
                  Home
                </span>
              </li>
              {isLoggedin ? (
                <Fragment>
                  <li className="nav-item">
                    <span
                      className={`nav-link ${
                        pathName === "/create" ? "active" : ""
                      }`}
                      onClick={() => history.push("/create")}
                    >
                      Create
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`nav-link ${
                        pathName === "/contract" ? "active" : ""
                      }`}
                      onClick={() => history.push("/contract")}
                    >
                      Contract List
                    </span>
                  </li>
                </Fragment>
              ) : null}
              <li className="nav-item">
                <span
                  className={`nav-link ${
                    pathName === "/about-us" ? "active" : ""
                  }`}
                  onClick={() => history.push("/about-us")}
                >
                  About Us
                </span>
              </li>
            </ul>
          </div>
          <div className="col-md col-6 col-lg-auto profileMenu order-2">
            {isLoggedin ? (
              <div className="dropdown profile__dropdown">
                <span
                  className=""
                  id="profileDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {getInitials}
                </span>
                <ul className="dropdown-menu" aria-labelledby="profileDropdown">
                  <li>
                    <span
                      onClick={() => history.push("/profile")}
                      className="dropdown-item cursor-pointer"
                    >
                      <img src={profile} alt="profile" /> Edit Profile
                    </span>
                  </li>
                  <li>
                    <span
                      onClick={() => history.push("/participants")}
                      className="dropdown-item cursor-pointer"
                    >
                      <img src={contactIcon} alt="profile" /> Participants
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item cursor-pointer"
                      onClick={() => handleLogout()}
                    >
                      <img src={logoff} alt="profile" /> Logout
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => history.push("/login")}
              >
                Login
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
    // Header End
  );
};
export default HeaderComponent;
