import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  IChangePasswordFormValues,
  IProfileFormValues,
} from "../../utils/Interfaces/dashboardInterface";
import * as ActionTypes from "../Actions/actionTypes";
import { changePasswordApi, updateProfileApi } from "../../apis/dashbaordApis";
import {
  changePasswordFailure,
  changePasswordSuccess,
  profileUpdateFailure,
  profileUpdateSuccess,
} from "../Actions/dashboard";
import { ReduxState } from "../Reducers";
import { ILoginResponse } from "../../utils/Interfaces/authInterfaces";
import history from "../../utils/history";

type Params = {
  profileData: IProfileFormValues;
  changePasswordData: IChangePasswordFormValues;
  type: string;
};

function* updateProfile({ profileData }: Params) {
  try {
    const token: string = yield select(
      (state: ReduxState) => state.auth.userInfo?.token
    );
    yield call(updateProfileApi, profileData.name, token);
    yield put(profileUpdateSuccess(profileData));
  } catch (error: any) {
    const { response } = error;
    if (
      response?.data.message === "Unauthorized" ||
      response?.data.message === "Unauthorized"
    ) {
      localStorage.removeItem("token");
      history.push("/login");
    }
    yield put(profileUpdateFailure(response?.data.message));
  }
}

function* changePassword({ changePasswordData }: Params) {
  try {
    const userInfo: ILoginResponse = yield select(
      (state: ReduxState) => state.auth.userInfo
    );
    const userDetails = localStorage.getItem("userDetails");
    const { email } = userDetails ? JSON.parse(userDetails) : null;
    const emailData = userInfo?.email ? userInfo?.email : email ? email : "";
    const response: Object = yield call(
      changePasswordApi,
      changePasswordData,
      emailData,
      userInfo?.token
    );
    yield put(changePasswordSuccess(response));
  } catch (error: any) {
    const { response } = error;
    if (
      response?.data.message === "Unauthorized" ||
      response?.data.message === "Unauthorized"
    ) {
      localStorage.removeItem("token");
      history.push("/login");
    }
    yield put(changePasswordFailure(response?.data.message));
  }
}

function* watchGetRequest() {
  yield takeLatest(ActionTypes.UPDATE_PROFILE_REQUEST, updateProfile);
  yield takeLatest(ActionTypes.CHANGE_PASSWORD_REQUEST, changePassword);
}

export default function* sagas() {
  yield all([watchGetRequest()]);
}
