import { authInstance } from "../services/api";
import { IChangePasswordFormValues } from "../utils/Interfaces/dashboardInterface";

const token = localStorage.getItem("token");
export const updateProfileApi = async (name: string, tokenData: string) => {
  const response: any = await authInstance.put<string>(
    "/v1/user/update",
    {
      name: name,
    },
    {
      headers: {
        Authorization: tokenData ? tokenData : token ? token : "",
      },
    }
  );
  return response;
};

export const changePasswordApi = async (
  changePasswordData: IChangePasswordFormValues,
  email: string,
  tokenData: string
) => {
  const response: any = await authInstance.post<string>(
    "/v1/user/change-password",
    {
      email: email,
      oldPassword: changePasswordData.oldPassword,
      newPassword: changePasswordData.newPassword,
    },
    {
      headers: {
        Authorization: tokenData ? tokenData : token ? token : "",
      },
    }
  );
  return response;
};
