import React from "react";
const HomePage = React.lazy(() => import("../pages/HomePages/home"));
const AboutUsPage = React.lazy(() => import("../pages/HomePages/aboutUs"));
const LoginPage = React.lazy(() => import("../pages/AuthPages/login"));
const SignupPage = React.lazy(() => import("../pages/AuthPages/signup"));
const ForgotPasswordPage = React.lazy(
  () => import("../pages/AuthPages/forgotPassword")
);
const ResetPasswordPage = React.lazy(
  () => import("../pages/AuthPages/resetPassword")
);
const VerifyUserPage = React.lazy(
  () => import("../pages/AuthPages/verifyUser")
);
const ProfilePage = React.lazy(() => import("../pages/ProfilePage/profile"));
const CreateContractPage = React.lazy(() => import("../pages/ContractPages"));
const ContractListPage = React.lazy(
  () => import("../pages/ContractPages/contractList")
);
const ParticipantstListPage = React.lazy(
  () => import("../pages/ContractPages/participantsList")
);

const Page404 = React.lazy(() => import("../pages/page404"));

export const RoutesList = [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: HomePage,
    isAuthentication: false,
  },
  {
    path: "/about-us",
    exact: true,
    name: "About",
    component: AboutUsPage,
    isAuthentication: false,
  },
  {
    path: "/login",
    exact: true,
    name: "Login",
    component: LoginPage,
    isAuthentication: false,
  },
  {
    path: "/signup",
    exact: true,
    name: "Signup",
    component: SignupPage,
    isAuthentication: false,
  },
  {
    path: "/forgot-password",
    exact: true,
    name: "Forgot Password",
    component: ForgotPasswordPage,
    isAuthentication: false,
  },
  {
    path: "/reset-password",
    exact: true,
    name: "Reset Password",
    component: ResetPasswordPage,
    isAuthentication: false,
  },
  {
    path: "/verify-user",
    exact: true,
    name: "Verify User",
    component: VerifyUserPage,
    isAuthentication: false,
  },
  {
    exact: true,
    path: "*",
    name: "Page 404",
    component: Page404,
    isAuthentication: false,
  },
];

export const PrivateRoutesList = [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: HomePage,
    isAuthentication: false,
  },
  {
    path: "/about-us",
    exact: true,
    name: "About",
    component: AboutUsPage,
    isAuthentication: false,
  },
  {
    path: "/profile",
    exact: true,
    name: "Profile",
    component: ProfilePage,
    isAuthentication: true,
  },
  {
    path: "/create",
    exact: true,
    name: "Create Contract",
    component: CreateContractPage,
    isAuthentication: true,
  },
  {
    path: "/contract",
    exact: true,
    name: "Contract List",
    component: ContractListPage,
    isAuthentication: true,
  },
  {
    path: "/participants",
    exact: true,
    name: "Participants List",
    component: ParticipantstListPage,
    isAuthentication: true,
  },
  {
    path: "/verify-user",
    exact: true,
    name: "Verify User",
    component: VerifyUserPage,
    isAuthentication: false,
  },
  {
    exact: true,
    path: "*",
    name: "Page 404",
    component: Page404,
    isAuthentication: false,
  },
];
