import { useEffect, useRef } from "react";

export const useOnChange = <T>(
  value: T,
  onChange: (value: T, prevValue: T) => void
) => {
  const previous = useRef<T>(value);
  useEffect(() => {
    if (previous.current !== value) {
      onChange(value, previous.current);
    }
    previous.current = value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
};

export const useClickedOutside = (ref: any, onClickOutside?: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        typeof onClickOutside === "function" && onClickOutside();
      }
    };

    // Bind the event listener
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref, onClickOutside]);
};

export const useScript = function (file: any) {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = file;
    script.type = "type/javascript";
    script.async = true;
    script.onload = () => console.log("**********Script Loaded");
    ;

    document.body.appendChild(script);
  }, [file]);
};
