import {
  IAccountFormValues,
  IContractFormValues,
} from "../../utils/Interfaces/contractInterface";
import * as ActionTypes from "./actionTypes";

//Get user participants
export const getParticipantsRequest = () => ({
  type: ActionTypes.GET_USER_PARTICIPANTS_REQUEST,
});
export const getParticipantsSuccess = (response: any) => ({
  type: ActionTypes.GET_USER_PARTICIPANTS_SUCCESS,
  response,
});
export const getParticipantsFailure = (error: string) => ({
  type: ActionTypes.GET_USER_PARTICIPANTS_FAILURE,
  error,
});

//Create User contract
export const createContractRequest = (contractData: IContractFormValues) => ({
  type: ActionTypes.CREATE_CONTRACT_REQUEST,
  contractData,
});
export const createContractSuccess = (response: any) => ({
  type: ActionTypes.CREATE_CONTRACT_SUCCESS,
  response,
});
export const createContractFailure = (error: string) => ({
  type: ActionTypes.CREATE_CONTRACT_FAILURE,
  error,
});

//Get Contract list
export const contractListRequest = () => ({
  type: ActionTypes.CONTRACT_LIST_REQUEST,
});
export const contractListSuccess = (response: any) => ({
  type: ActionTypes.CONTRACT_LIST_SUCCESS,
  response,
});
export const contractListFailure = (error: string) => ({
  type: ActionTypes.CONTRACT_LIST_FAILURE,
  error,
});

//Get Contract list
export const addAccountRequest = (accountData: IAccountFormValues) => ({
  type: ActionTypes.ADD_ACCOUNT_REQUEST,
  accountData,
});
export const addAccountSuccess = (response: any) => ({
  type: ActionTypes.ADD_ACCOUNT_SUCCESS,
  response,
});
export const addAccountFailure = (error: string) => ({
  type: ActionTypes.ADD_ACCOUNT_FAILURE,
  error,
});

//Get Contract list
export const updateAccountRequest = (accountData: IAccountFormValues) => ({
  type: ActionTypes.UPDATE_ACCOUNT_REQUEST,
  accountData,
});
export const updateAccountSuccess = (response: any) => ({
  type: ActionTypes.UPDATE_ACCOUNT_SUCCESS,
  response,
});
export const updateAccountFailure = (error: string) => ({
  type: ActionTypes.UPDATE_ACCOUNT_FAILURE,
  error,
});

//Get Contract list
export const deleteAccountRequest = (participantId: string) => ({
  type: ActionTypes.DELETE_ACCOUNT_REQUEST,
  participantId,
});
export const deleteAccountSuccess = (response: any) => ({
  type: ActionTypes.DELETE_ACCOUNT_SUCCESS,
  response,
});
export const deleteAccountFailure = (error: string) => ({
  type: ActionTypes.DELETE_ACCOUNT_FAILURE,
  error,
});

export const contractAddressRequest = () => ({
  type: ActionTypes.CONTRACT_ADDRESS_REQUEST,
});
export const contractAddressSuccess = (response: any) => ({
  type: ActionTypes.CONTRACT_ADDRESS_SUCCESS,
  response,
});
export const contractAddressFailure = (error: string) => ({
  type: ActionTypes.CONTRACT_ADDRESS_FAILURE,
  error,
});
// Manual Sync contract Address
export const syncContractAddressRequest = (contractId: string) => ({
  type: ActionTypes.SYNC_CONTRACT_ADDRESS_REQUEST,
  contractId
});
export const syncContractAddressSuccess = (response: any) => ({
  type: ActionTypes.SYNC_CONTRACT_ADDRESS_SUCCESS,
  response,
});
export const syncContractAddressFailure = (error: string) => ({
  type: ActionTypes.SYNC_CONTRACT_ADDRESS_FAILURE,
  error,
});
