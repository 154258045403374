import {
  ADD_ACCOUNT_FAILURE,
  ADD_ACCOUNT_REQUEST,
  ADD_ACCOUNT_SUCCESS,
  CONTRACT_ADDRESS_FAILURE,
  CONTRACT_ADDRESS_REQUEST,
  CONTRACT_ADDRESS_SUCCESS,
  CONTRACT_LIST_FAILURE,
  CONTRACT_LIST_REQUEST,
  CONTRACT_LIST_SUCCESS,
  CREATE_CONTRACT_FAILURE,
  CREATE_CONTRACT_REQUEST,
  CREATE_CONTRACT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  GET_USER_PARTICIPANTS_FAILURE,
  GET_USER_PARTICIPANTS_REQUEST,
  GET_USER_PARTICIPANTS_SUCCESS,
  SYNC_CONTRACT_ADDRESS_FAILURE,
  SYNC_CONTRACT_ADDRESS_REQUEST,
  SYNC_CONTRACT_ADDRESS_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
} from "../../redux/Actions/actionTypes";
import { replaceOrAdd } from "../../utils/HelperFunctions/functions";
import {
  IContractFormValues,
  IParticipantsFormValues,
} from "../../utils/Interfaces/contractInterface";

export type State = {
  contractLoading: boolean;
  contractError: string | null;
  contractSuccess: boolean;

  participants: Array<IParticipantsFormValues> | [];
  participantsLoading: boolean;
  participantsError: string | null;
  participantsSuccess: boolean;

  contractList: Array<IContractFormValues> | [];
  contractListLoading: boolean;
  contractListError: string | null;
  contractListSuccess: boolean;

  addAccountLoading: boolean;
  addAccountError: string | null;
  existParticipantId: string;
  addAccountSuccess: boolean;

  contractAddress: string | null;
  contractAddressSuccess: boolean;
  contractAddressError: string | null;

  updateAccountLoading: boolean;
  updateAccountError: string | null;
  updateAccountSuccess: boolean;

  deleteAccountLoading: boolean;
  deleteAccountError: string | null;
  deleteAccountSuccess: boolean;

  syncContractAddressLoading: boolean;
  syncContractAddressSuccess: boolean;
  syncContractAddressError: string | null;
};

export type Action = {
  type: string;
  payload: {};
  error: Error;
  response: any;
};

const initialState = {
  contractLoading: false,
  contractError: null,
  contractSuccess: false,

  participants: [],
  participantsLoading: false,
  participantsError: null,
  participantsSuccess: false,

  contractList: [],
  contractListLoading: false,
  contractListError: null,
  contractListSuccess: false,

  addAccountLoading: false,
  existParticipantId: "",
  addAccountError: null,
  addAccountSuccess: false,

  updateAccountLoading: false,
  updateAccountError: null,
  updateAccountSuccess: false,

  deleteAccountLoading: false,
  deleteAccountError: null,
  deleteAccountSuccess: false,

  contractAddress: null,
  contractAddressSuccess: false,
  contractAddressError: null,

  syncContractAddressLoading: false,
  syncContractAddressSuccess: false,
  syncContractAddressError: null,
};

const createContract = (state: State, action: Action) => ({
  ...state,
  contractLoading: true,
  contractError: null,
  contractSuccess: false,
});

const createContractFailure = (state: State, action: Action) => ({
  ...state,
  contractLoading: false,
  contractError: action.error,
  contractSuccess: false,
});

const createContractSuccess = (state: State, action: Action) => {
  localStorage.removeItem("EthLog");
  localStorage.removeItem("contractAddress");
  return {
    ...state,
    contractLoading: false,
    contractError: null,
    contractSuccess: true,

    contractAddress: null,
    contractAddressError: null,
    contractAddressSuccess: false,
  };
};

const getParticipants = (state: State, action: Action) => ({
  ...state,
  participantsLoading: true,
  participantsError: null,
  participantsSuccess: false,
});

const getParticipantsFailure = (state: State, action: Action) => ({
  ...state,
  participantsLoading: false,
  participantsError: action.error,
  participantsSuccess: false,
});

const getParticipantsSuccess = (state: State, action: Action) => {
  const { participants } = action.response;
  return {
    ...state,
    participants: participants,
    participantsLoading: false,
    participantsError: null,
    participantsSuccess: true,
  };
};

const getContractList = (state: State, action: Action) => ({
  ...state,
  contractListLoading: true,
  contractListError: null,
  contractListSuccess: false,
});

const getContractListFailure = (state: State, action: Action) => ({
  ...state,
  contractListLoading: false,
  contractListError: action.error,
  contractListSuccess: false,
});

const getContractListSuccess = (state: State, action: Action) => {
  const { contracts } = action.response;
  return {
    ...state,
    contractList: contracts,
    contractListLoading: false,
    contractListError: null,
    contractListSuccess: true,
  };
};

const addAccount = (state: State, action: Action) => ({
  ...state,
  addAccountLoading: true,
  addAccountError: null,
  addAccountSuccess: false,
  existParticipantId: "",
});

const addAccountFailure = (state: State, action: Action) => ({
  ...state,
  addAccountLoading: false,
  addAccountError: action.error,
  addAccountSuccess: false,
  existParticipantId: "",
});

const addAccountSuccess = (state: State, action: Action) => {
  const { name, id, email, account } = action.response;
  const participantsData =
    state.participants && state.participants.length
      ? [...state.participants]
      : [];
  const isAlreadyExit = participantsData.some((data: any) => data.id === id);
  if (name && !isAlreadyExit) {
    participantsData.push({
      name: name,
      email: email,
      account: account,
      pid: id,
      percent: "",
    });
  }
  return {
    ...state,
    participants: participantsData,
    addAccountLoading: false,
    addAccountError: null,
    addAccountSuccess: true,
    existParticipantId: isAlreadyExit ? id : "",
  };
};

const updateAccount = (state: State, action: Action) => ({
  ...state,
  updateAccountLoading: true,
  updateAccountError: null,
  updateAccountSuccess: false,
});

const updateAccountFailure = (state: State, action: Action) => ({
  ...state,
  updateAccountLoading: false,
  updateAccountError: action.error,
  updateAccountSuccess: false,
});

const updateAccountSuccess = (state: State, action: Action) => {
  const { name, id, email, account } = action.response;
  let participantsData =
    state.participants && state.participants.length
      ? [...state.participants]
      : [];
  const updateValue = { name: name, email: email, id: id, account: account };
  participantsData = replaceOrAdd(
    participantsData,
    updateValue,
    (participant: IParticipantsFormValues) => participant.id === id
  );
  return {
    ...state,
    participants: participantsData,
    updateAccountLoading: false,
    updateAccountError: null,
    updateAccountSuccess: true,
  };
};

const deleteAccount = (state: State, action: Action) => ({
  ...state,
  deleteAccountLoading: true,
  deleteAccountError: null,
  deleteAccountSuccess: false,
});

const deleteAccountFailure = (state: State, action: Action) => ({
  ...state,
  deleteAccountLoading: false,
  deleteAccountError: action.error,
  deleteAccountSuccess: false,
});

const deleteAccountSuccess = (state: State, action: Action) => {
  const { id } = action.response;
  let participantsData =
    state.participants && state.participants.length
      ? [...state.participants]
      : [];
  const updatedValue = participantsData.filter(
    (data: IParticipantsFormValues) => data.id !== id
  );
  return {
    ...state,
    participants: updatedValue && updatedValue.length ? updatedValue : null,
    deleteAccountLoading: false,
    deleteAccountError: null,
    deleteAccountSuccess: true,
  };
};

const contractAddressRequest = (state: State, action: Action) => {
  return {
    ...state,
    contractAddressError: null,
    contractAddressSuccess: false,
  };
};

const contractAddressSuccess = (state: State, action: Action) => {
  const { contractAddress } = action.response;
  return {
    ...state,
    contractAddress,
    contractAddressError: null,
    contractAddressSuccess: true,
  };
};

const contractAddressFailure = (state: State, action: Action) => {
  const { message } = action.error;
  return {
    ...state,
    contractAddress: "",
    contractAddressError: message,
    contractAddressSuccess: true,
  };
};

const syncContractAddressRequest = (state: State, action: Action) => {
  return {
    ...state,
    syncContractAddressLoading: true,
    syncContractAddressError: null,
    syncContractAddressSuccess: false,
  };
};

const syncContractAddressSuccess = (state: State, action: Action) => {
  const { address, id } = action.response;

  let contractListData =
    state.contractList && state.contractList.length
      ? [...state.contractList]
      : [];
  const updateContract = contractListData.filter(
    (data: IContractFormValues) => data.id === id
  )[0];

  const updateValue = { ...updateContract, address: address };
  contractListData = replaceOrAdd(
    contractListData,
    updateValue,
    (contract: IContractFormValues) => contract.id === id
  );
  return {
    ...state,
    contractList: address ? contractListData : state.contractList,
    syncContractAddressLoading: false,
    syncContractAddressError: null,
    syncContractAddressSuccess: true,
  };
};

const syncContractAddressFailure = (state: State, action: Action) => {
  return {
    ...state,
    syncContractAddressLoading: false,
    syncContractAddressError: action.error,
    syncContractAddressSuccess: true,
  };
};

const dashboardReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case CREATE_CONTRACT_REQUEST:
      return createContract(state, action);
    case CREATE_CONTRACT_SUCCESS:
      return createContractSuccess(state, action);
    case CREATE_CONTRACT_FAILURE:
      return createContractFailure(state, action);
    case GET_USER_PARTICIPANTS_REQUEST:
      return getParticipants(state, action);
    case GET_USER_PARTICIPANTS_SUCCESS:
      return getParticipantsSuccess(state, action);
    case GET_USER_PARTICIPANTS_FAILURE:
      return getParticipantsFailure(state, action);
    case CONTRACT_LIST_REQUEST:
      return getContractList(state, action);
    case CONTRACT_LIST_SUCCESS:
      return getContractListSuccess(state, action);
    case CONTRACT_LIST_FAILURE:
      return getContractListFailure(state, action);
    case ADD_ACCOUNT_REQUEST:
      return addAccount(state, action);
    case ADD_ACCOUNT_SUCCESS:
      return addAccountSuccess(state, action);
    case ADD_ACCOUNT_FAILURE:
      return addAccountFailure(state, action);
    case UPDATE_ACCOUNT_REQUEST:
      return updateAccount(state, action);
    case UPDATE_ACCOUNT_SUCCESS:
      return updateAccountSuccess(state, action);
    case UPDATE_ACCOUNT_FAILURE:
      return updateAccountFailure(state, action);
    case DELETE_ACCOUNT_REQUEST:
      return deleteAccount(state, action);
    case DELETE_ACCOUNT_SUCCESS:
      return deleteAccountSuccess(state, action);
    case DELETE_ACCOUNT_FAILURE:
      return deleteAccountFailure(state, action);
    case CONTRACT_ADDRESS_REQUEST:
      return contractAddressRequest(state, action);
    case CONTRACT_ADDRESS_SUCCESS:
      return contractAddressSuccess(state, action);
    case CONTRACT_ADDRESS_FAILURE:
      return contractAddressFailure(state, action);
    case SYNC_CONTRACT_ADDRESS_REQUEST:
      return syncContractAddressRequest(state, action);
    case SYNC_CONTRACT_ADDRESS_SUCCESS:
      return syncContractAddressSuccess(state, action);
    case SYNC_CONTRACT_ADDRESS_FAILURE:
      return syncContractAddressFailure(state, action);
    default:
      return state;
  }
};

export default dashboardReducer;
