import {
  IChangePasswordFormValues,
  IProfileFormValues,
} from "../../utils/Interfaces/dashboardInterface";
import * as ActionTypes from "./actionTypes";

//Profile Update Actions
export const profileUpdateRequest = (profileData: IProfileFormValues) => ({
  type: ActionTypes.UPDATE_PROFILE_REQUEST,
  profileData,
});
export const profileUpdateSuccess = (response: IProfileFormValues) => ({
  type: ActionTypes.UPDATE_PROFILE_SUCCESS,
  response,
});
export const profileUpdateFailure = (error: string) => ({
  type: ActionTypes.UPDATE_PROFILE_FAILURE,
  error,
});

//Change Password Actions
export const changePasswordRequest = (
  changePasswordData: IChangePasswordFormValues
) => ({
  type: ActionTypes.CHANGE_PASSWORD_REQUEST,
  changePasswordData,
});
export const changePasswordSuccess = (response: any) => ({
  type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
  response,
});
export const changePasswordFailure = (error: string) => ({
  type: ActionTypes.CHANGE_PASSWORD_FAILURE,
  error,
});
