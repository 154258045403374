import {
  ILoginFormValues,
  ILoginResponse,
  ISignupFormValues,
  ISocialLoginValues,
} from "../../utils/Interfaces/authInterfaces";
import * as ActionTypes from "./actionTypes";

//Login Actions
export const loginRequest = (data: ILoginFormValues) => ({
  type: ActionTypes.USER_LOGIN_REQUEST,
  data,
});
export const loginSuccess = (response: ILoginResponse) => ({
  type: ActionTypes.USER_LOGIN_SUCCESS,
  response,
});
export const loginFailure = (error: string) => ({
  type: ActionTypes.USER_LOGIN_FAILURE,
  error,
});

//Signup Actions
export const signupRequest = (signupData: ISignupFormValues) => ({
  type: ActionTypes.SIGNUP_REQUEST,
  signupData,
});
export const signupSuccess = (response: any) => ({
  type: ActionTypes.SIGNUP_SUCCESS,
  response,
});
export const signupFailure = (error: string) => ({
  type: ActionTypes.SIGNUP_FAILURE,
  error,
});

//Login Actions
export const logoutRequest = () => ({
  type: ActionTypes.LOGOUT_REQUEST,
});
export const logoutSuccess = () => ({
  type: ActionTypes.LOGOUT_SUCCESS,
});
export const logoutFailure = (error: string) => ({
  type: ActionTypes.LOGOUT_FAILURE,
  error,
});

//Forgot Password Actions
export const forgotPasswordRequest = (email: string) => ({
  type: ActionTypes.FORGOT_PASSWORD_REQUEST,
  email,
});
export const forgotPasswordSuccess = (response: any) => ({
  type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
  response,
});
export const fortgotPasswordFailure = (error: string) => ({
  type: ActionTypes.FORGOT_PASSWORD_FAILURE,
  error,
});

//Verify User Link Actions
export const verifyUserLinkRequest = (token: string) => ({
  type: ActionTypes.VERIFY_USER_REQUEST,
  token,
});
export const verifyUserLinkSuccess = (response: any) => ({
  type: ActionTypes.VERIFY_USER_SUCCESS,
  response,
});
export const verifyUserLinkFailure = (error: string) => ({
  type: ActionTypes.VERIFY_USER_FAILURE,
  error,
});

//Reset Password Actions
export const resetPasswordRequest = (
  token: string,
  password: string | number
) => ({
  type: ActionTypes.RESET_PASSWORD_REQUEST,
  token,
  password,
});
export const resetPasswordSuccess = (response: any) => ({
  type: ActionTypes.RESET_PASSWORD_SUCCESS,
  response,
});
export const resetPasswordFailure = (error: string) => ({
  type: ActionTypes.RESET_PASSWORD_FAILURE,
  error,
});

//Reset Password Actions
export const socialLoginRequest = (socialData: ISocialLoginValues) => ({
  type: ActionTypes.SOCIAL_LOGIN_REQUEST,
  socialData,
});
export const socialLoginSuccess = (response: any) => ({
  type: ActionTypes.SOCIAL_LOGIN_SUCCESS,
  response,
});
export const socialLoginFailure = (error: string) => ({
  type: ActionTypes.SOCIAL_LOGIN_FAILURE,
  error,
});

//Verify User Email Actions
export const verifyUserEmailRequest = (token: string) => ({
  type: ActionTypes.VERIFY_USER_EMAIL_REQUEST,
  token,
});
export const verifyUserEmailSuccess = (response: any) => ({
  type: ActionTypes.VERIFY_USER_EMAIL_SUCCESS,
  response,
});
export const verifyUserEmailFailure = (error: string) => ({
  type: ActionTypes.VERIFY_USER_EMAIL_FAILURE,
  error,
});