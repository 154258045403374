import React from "react";

type Props = {
  history: any;
};

const Footer = ({ history }: Props) => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="footer__inner">
          <div className="row">
            <div className="col-md-6">
              <div className="copyright">
                ©2021 EthSplitter, All Rights Reserved.
              </div>
            </div>
            <div className="col-md-6">
              <div className=" footer__menu">
                <span className="cursor-pointer">Privacy Policy</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
