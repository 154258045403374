import { authInstance } from "../services/api";
import {
  IAccountFormValues,
  IContractFormValues,
} from "../utils/Interfaces/contractInterface";
const token = localStorage.getItem("token");
const userInfo = localStorage.getItem("userDetails");
const userData = userInfo ? JSON.parse(userInfo) : null;

export const createContractApi = async (
  contractData: IContractFormValues,
  tokenData: string,
  id: string
) => {
  const response: any = await authInstance.post<string>(
    `/v1/user/${userData ? userData.id : id ? id : null}/contract`,
    contractData,
    {
      headers: {
        Authorization: tokenData ? tokenData : token ? token : "",
      },
    }
  );
  return response;
};

export const getParticipantsApi = async (tokenData: string, id: string) => {
  const response: any = await authInstance.get<string>(
    `/v1/user/${userData ? userData.id : id ? id : null}/participant`,
    {
      headers: {
        Authorization: tokenData ? tokenData : token ? token : "",
      },
    }
  );
  return response;
};

export const getContractListApi = async (tokenData: string, id: string) => {
  console.log("+++++++++userData", userData);

  const response: any = await authInstance.get<string>(
    `/v1/user/${
      id ? id : userData ? userData.id : null
    }/contract?sort=created&dir=desc`,
    {
      headers: {
        Authorization: tokenData ? tokenData : token ? token : "",
      },
    }
  );
  return response;
};

export const addAccountApi = async (
  accountData: IAccountFormValues,
  tokenData: string,
  id: string
) => {
  const response: any = await authInstance.post<string>(
    `/v1/user/${userData ? userData.id : id ? id : null}/participant`,
    accountData,
    {
      headers: {
        Authorization: tokenData ? tokenData : token ? token : "",
      },
    }
  );
  return response;
};

export const updateAccountApi = async (
  accountData: IAccountFormValues,
  tokenData: string,
  id: string
) => {
  const response: any = await authInstance.put<string>(
    `/v1/user/${userData ? userData.id : id ? id : null}/participant/${
      accountData?.pid
    }`,
    accountData,
    {
      headers: {
        Authorization: tokenData ? tokenData : token ? token : "",
      },
    }
  );
  return response;
};

export const deleteAccountApi = async (
  participantId: string,
  tokenData: string,
  id: string
) => {
  const response: any = await authInstance.delete<string>(
    `/v1/user/${
      userData ? userData.id : id ? id : null
    }/participant/${participantId}`,
    {
      headers: {
        Authorization: tokenData ? tokenData : token ? token : "",
      },
    }
  );
  return response;
};

export const syncContractAddressApi = async (
  contractId: string,
  tokenData: string,
  id: string
) => {
  const response: any = await authInstance.get<string>(
    `/v1/user/${
      userData ? userData.id : id ? id : null
    }/contract/txStatus/${contractId}`,
    {
      headers: {
        Authorization: tokenData ? tokenData : token ? token : "",
      },
    }
  );
  return response;
};
