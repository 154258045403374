import { Router } from "react-router-dom";
import history from "./utils/history";
import Routes from "./routesHoc";
import { ToastProvider } from "./context/toastContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/js/main.js";
import "./assets/sass/main.scss";
import "./App.css";
function App() {
  return (
    <Router history={history}>
      <ToastProvider>
        <Routes />
      </ToastProvider>
    </Router>
  );
}

export default App;
