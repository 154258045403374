import {
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  USER_LOGIN_REQUEST,
  SIGNUP_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  SOCIAL_LOGIN_REQUEST,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAILURE,
  VERIFY_USER_EMAIL_REQUEST,
  VERIFY_USER_EMAIL_SUCCESS,
  VERIFY_USER_EMAIL_FAILURE,
  UPDATE_PROFILE_SUCCESS,
} from "../../redux/Actions/actionTypes";
import history from "../../utils/history";
import { ILoginResponse } from "../../utils/Interfaces/authInterfaces";

export type State = {
  isLoggedIn: boolean;
  userInfo: {
    email: string;
    name: string;
    token: string;
  } | null;
  getUserError: string | null;
  getUserLoading: boolean;
  forgotPasswordLoading: boolean;
  forgotPasswordError: string | null;
  forgotPasswordSuccess: boolean;
  isVerifiedUser: boolean;

  verifyUserError: string | null;
  verifyUserSuccess: boolean;
  verifyUserLoading: boolean;

  resetPasswordLoading: boolean;
  resetPasswordError: string | null;
  resetPasswordSuccess: boolean;

  socialLoginLoading: boolean;
  socialLoginError: string | null;
  socialLoginSuccess: boolean;

  verifyUserEmailError: string | null;
  verifyUserEmailSuccess: boolean;
  verifyUserEmailLoading: boolean;
};

export type Action = {
  type: string;
  payload: {};
  error: Error;
  response: ILoginResponse;
};

const initialState = {
  isLoggedIn: false,
  getUserLoading: false,
  userInfo: null,
  getUserError: null,
  forgotPasswordLoading: false,
  forgotPasswordError: null,
  forgotPasswordSuccess: false,
  isVerifiedUser: false,
  resetPasswordLoading: false,
  resetPasswordError: null,
  resetPasswordSuccess: false,
  verifyUserError: null,
  verifyUserSuccess: false,
  verifyUserLoading: false,
  socialLoginLoading: false,
  socialLoginError: null,
  socialLoginSuccess: false,
  verifyUserEmailError: null,
  verifyUserEmailSuccess: false,
  verifyUserEmailLoading: false,
};

const signUp = (state: State, action: Action) => ({
  ...state,
  getUserError: null,
  getUserLoading: true,
});

const signUpFailure = (state: State, action: Action) => ({
  ...state,
  getUserError: action.error,
  getUserLoading: false,
});

const signUpSuccess = (state: State, action: Action) => {
  const { email, name, token, id, isVerified } = action.response;
  localStorage.setItem("token", token);
  localStorage.setItem(
    "userDetails",
    JSON.stringify({ email: email, name: name, id: id, isVerified: isVerified })
  );
  history.push("/");
  return {
    ...state,
    isLoggedIn: true,
    userInfo: {
      email: email,
      name: name,
      token: token,
      id: id,
      isVerified: isVerified,
    },
    getUserLoading: false,
  };
};

const login = (state: State, action: Action) => ({
  ...state,
  getUserError: null,
  getUserLoading: true,
});

const loginFailure = (state: State, action: Action) => {
  return {
    ...state,
    getUserError: action.error,
    getUserLoading: false,
  };
};

const loginSuccess = (state: State, action: Action) => {
  const { email, name, token, id, isVerified } = action.response;
  localStorage.setItem("token", token);
  localStorage.setItem(
    "userDetails",
    JSON.stringify({ email: email, name: name, id: id, isVerified: isVerified })
  );
  history.push("/");
  return {
    ...state,
    getUserError: null,
    isLoggedIn: true,
    userInfo: {
      email: email,
      name: name,
      token: token,
      id: id,
      isVerified: isVerified,
    },
    getUserLoading: false,
  };
};

const logoutFailure = (state: State, action: Action) => ({
  ...state,
  getUserError: action.error,
});

const logoutSuccess = (state: State, action: Action) => {
  localStorage.removeItem("token");
  localStorage.removeItem("userDetails");
  history.push("/");
  return {
    ...state,
    isLoggedIn: false,
    userInfo: null,
  };
};

const forgotPassword = (state: State, action: Action) => ({
  ...state,
  forgotPasswordError: null,
  forgotPasswordLoading: true,
  forgotPasswordSuccess: false,
});

const forgotPasswordFailure = (state: State, action: Action) => {
  return {
    ...state,
    forgotPasswordError: action.error,
    forgotPasswordLoading: false,
    forgotPasswordSuccess: false,
  };
};

const forgotPasswordSuccess = (state: State, action: Action) => {
  return {
    ...state,
    forgotPasswordError: null,
    forgotPasswordLoading: false,
    forgotPasswordSuccess: true,
  };
};

const verifyUserLink = (state: State, action: Action) => ({
  ...state,
  isVerifiedUser: false,
  verifyUserLoading: true,
  verifyUserError: null,
  verifyUserSuccess: false,
});

const verifyUserLinkFailure = (state: State, action: Action) => {
  return {
    ...state,
    isVerifiedUser: false,
    verifyUserLoading: false,
    verifyUserError: action.error,
    verifyUserSuccess: false,
  };
};

const verifyUserLinkSuccess = (state: State, action: Action) => {
  return {
    ...state,
    isVerifiedUser: true,
    verifyUserLoading: false,
    verifyUserError: null,
    verifyUserSuccess: true,
  };
};
const resetPassword = (state: State, action: Action) => ({
  ...state,
  resetPasswordLoading: true,
  resetPasswordError: null,
  resetPasswordSuccess: false,
});

const resetPasswordFailure = (state: State, action: Action) => {
  return {
    ...state,
    resetPasswordLoading: false,
    resetPasswordError: action.error,
    resetPasswordSuccess: false,
  };
};

const resetPasswordSuccess = (state: State, action: Action) => {
  return {
    ...state,
    resetPasswordLoading: false,
    resetPasswordError: null,
    resetPasswordSuccess: true,
  };
};
const socialLogin = (state: State, action: Action) => ({
  ...state,
  socialLoginLoading: true,
  socialLoginError: null,
  socialLoginSuccess: false,
});

const socialLoginFailure = (state: State, action: Action) => {
  return {
    ...state,
    socialLoginLoading: false,
    socialLoginError: action.error,
    socialLoginSuccess: false,
  };
};

const socialLoginSuccess = (state: State, action: Action) => {
  const { email, name, token, id, isVerified } = action.response;
  localStorage.setItem("token", token);
  localStorage.setItem(
    "userDetails",
    JSON.stringify({ email: email, name: name, id: id, isVerified: isVerified })
  );
  history.push("/");
  return {
    ...state,
    socialLoginLoading: false,
    socialLoginError: null,
    socialLoginSuccess: true,
    userInfo: {
      email: email,
      name: name,
      token: token,
      id: id,
      isVerified: isVerified,
    },
  };
};

const verifyUserEmail = (state: State, action: Action) => ({
  ...state,
  verifyUserEmailLoading: true,
  verifyUserEmailError: null,
  verifyUserEmailSuccess: false,
});

const verifyUserEmailFailure = (state: State, action: Action) => {
  return {
    ...state,
    verifyUserEmailLoading: false,
    verifyUserEmailError: action.error,
    verifyUserEmailSuccess: false,
  };
};

const verifyUserEmailSuccess = (state: State, action: Action) => {
  const userInfo = localStorage.getItem("userDetails");
  const userData = userInfo ? JSON.parse(userInfo) : null;
  localStorage.setItem(
    "userDetails",
    JSON.stringify({
      email: userData.email,
      name: userData.name,
      id: userData.id,
      isVerified: true,
    })
  );
  return {
    ...state,
    isVerifiedUser: true,
    verifyUserEmailLoading: false,
    verifyUserEmailError: null,
    verifyUserEmailSuccess: true,
  };
};
const profileUpdateSuccess = (state: State, action: Action) => {
  const { name } = action.response;
  return {
    ...state,
    userInfo: {
      ...state.userInfo,
      name: name,
    },
  };
};

const authReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return signUp(state, action);
    case SIGNUP_SUCCESS:
      return signUpSuccess(state, action);
    case SIGNUP_FAILURE:
      return signUpFailure(state, action);
    case USER_LOGIN_REQUEST:
      return login(state, action);
    case USER_LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case USER_LOGIN_FAILURE:
      return loginFailure(state, action);
    case LOGOUT_SUCCESS:
      return logoutSuccess(state, action);
    case LOGOUT_FAILURE:
      return logoutFailure(state, action);
    case FORGOT_PASSWORD_REQUEST:
      return forgotPassword(state, action);
    case FORGOT_PASSWORD_SUCCESS:
      return forgotPasswordSuccess(state, action);
    case FORGOT_PASSWORD_FAILURE:
      return forgotPasswordFailure(state, action);
    case VERIFY_USER_REQUEST:
      return verifyUserLink(state, action);
    case VERIFY_USER_SUCCESS:
      return verifyUserLinkSuccess(state, action);
    case VERIFY_USER_FAILURE:
      return verifyUserLinkFailure(state, action);
    case RESET_PASSWORD_REQUEST:
      return resetPassword(state, action);
    case RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action);
    case RESET_PASSWORD_FAILURE:
      return resetPasswordFailure(state, action);
    case SOCIAL_LOGIN_REQUEST:
      return socialLogin(state, action);
    case SOCIAL_LOGIN_SUCCESS:
      return socialLoginSuccess(state, action);
    case SOCIAL_LOGIN_FAILURE:
      return socialLoginFailure(state, action);
    case VERIFY_USER_EMAIL_REQUEST:
      return verifyUserEmail(state, action);
    case VERIFY_USER_EMAIL_SUCCESS:
      return verifyUserEmailSuccess(state, action);
    case VERIFY_USER_EMAIL_FAILURE:
      return verifyUserEmailFailure(state, action);
    case UPDATE_PROFILE_SUCCESS:
      return profileUpdateSuccess(state, action);
    default:
      return state;
  }
};

export default authReducer;
