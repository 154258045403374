import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
} from "../../redux/Actions/actionTypes";

export type State = {
  profileLoading: boolean;
  updateProfileError: string | null;
  updateProfileSuccess: boolean;

  changePasswordLoading: boolean;
  changePasswordError: string | null;
  changePasswordSuccess: boolean;
};

export type Action = {
  type: string;
  payload: {};
  error: Error;
  response: any;
};

const initialState = {
  profileLoading: false,
  updateProfileError: null,
  updateProfileSuccess: false,

  changePasswordLoading: false,
  changePasswordError: null,
  changePasswordSuccess: false,
};

const updateProfile = (state: State, action: Action) => ({
  ...state,
  profileLoading: true,
  updateProfileError: null,
  updateProfileSuccess: false,
});

const updateProfileFailure = (state: State, action: Action) => ({
  ...state,
  updateProfileError: action.error,
  profileLoading: false,
  updateProfileSuccess: false,
});

const updateProfileSuccess = (state: State, action: Action) => {
  const { email, name, id } = action.response;
  localStorage.setItem(
    "userDetails",
    JSON.stringify({ email: email, name: name, id: id })
  );
  return {
    ...state,
    profileLoading: false,
    updateProfileFailure: null,
    updateProfileSuccess: true,
  };
};

const changePassword = (state: State, action: Action) => ({
  ...state,
  changePasswordLoading: true,
});

const changePasswordFailure = (state: State, action: Action) => ({
  ...state,
  changePasswordError: action.error,
  changePasswordLoading: false,
  changePasswordSuccess: false,
});

const changePasswordSuccess = (state: State, action: Action) => {
  return {
    ...state,
    changePasswordLoading: false,
    changePasswordError: null,
    changePasswordSuccess: true,
  };
};

const dashboardReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case UPDATE_PROFILE_REQUEST:
      return updateProfile(state, action);
    case UPDATE_PROFILE_SUCCESS:
      return updateProfileSuccess(state, action);
    case UPDATE_PROFILE_FAILURE:
      return updateProfileFailure(state, action);
    case CHANGE_PASSWORD_REQUEST:
      return changePassword(state, action);
    case CHANGE_PASSWORD_SUCCESS:
      return changePasswordSuccess(state, action);
    case CHANGE_PASSWORD_FAILURE:
      return changePasswordFailure(state, action);
    default:
      return state;
  }
};

export default dashboardReducer;
