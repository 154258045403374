import { Fragment, Suspense, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { PrivateRoutesList, RoutesList } from "../../routesHoc/routes";
import { useOnChange } from "../../utils/CustomHooks/hooks";
import Footer from "./footer";
import Header from "./header";
import { useLocation } from "react-router-dom";
import { AppLoader } from "../../common/appLoader";

type StateProps = {
  isLoggedIn: boolean;
};
type Props = {
  history: any;
} & StateProps;

const DefaultLayout = ({ history }: Props) => {
  const token = localStorage.getItem("token");

  const [isAuthPage, setIsAuthPage] = useState<boolean>(false);
  const location = useLocation();
  useOnChange(location, (routesData) => {
    const authRoutes = [
      "/signup",
      "/login",
      "/forgot-password",
      "/verify-user",
      "/reset-password",
    ];
    const { pathname } = location;
    if (authRoutes.includes(pathname)) {
      setIsAuthPage(true);
    } else {
      setIsAuthPage(false);
    }
  });
  useEffect(() => {
    const authRoutes = [
      "/signup",
      "/login",
      "/forgot-password",
      "/reset-password"
    ];
    const { pathname } = location;
    if (authRoutes.includes(pathname)) {
      setIsAuthPage(true);
    } else {
      setIsAuthPage(false);
    }
    if (token && authRoutes.includes(pathname)) {
      history.push("/");
    } else if (!token && !authRoutes.includes(pathname)) {
      if (pathname !== "/") {
        history.push("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {!isAuthPage ? (
        <Header
          history={history}
          isLoggedin={token ? true : false}
          pathName={location?.pathname}
        />
      ) : null}
      <Suspense fallback={<AppLoader />}>
        {!token ? (
          <Switch>
            {RoutesList.map((route: any, index: number) => {
              return (
                <Route
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  render={(props) => (
                    <route.component {...props} {...history} />
                  )}
                />
              );
            })}
          </Switch>
        ) : (
          <Switch>
            {PrivateRoutesList.map((route: any, index: number) => {
              return (
                <Route
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  render={(props) => (
                    <route.component {...props} {...history} />
                  )}
                />
              );
            })}
          </Switch>
        )}
      </Suspense>
      {!isAuthPage ? <Footer history={history} /> : null}
    </Fragment>
  );
};

export default DefaultLayout;
