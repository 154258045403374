import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderComponent from "../../common/headerComponent";
import { logoutRequest } from "../../redux/Actions/authentication";
import { ReduxState } from "../../redux/Reducers";
import { getNameInitials } from "../../utils/HelperFunctions/functions";

type Props = {
  history: any;
  isLoggedin: boolean;
  pathName: string | any;
};

const Header = ({ history, isLoggedin, pathName }: Props) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutRequest());
  };
  const userDetails = localStorage.getItem("userDetails");
  const userInfo = useSelector((state: ReduxState) => state.auth.userInfo);

  const userFullName: string = useMemo(() => {
    if (userInfo) {
      const { name } = userInfo;
      return name;
    } else if (userDetails) {
      const data = JSON.parse(userDetails);
      const { name } = data;
      return name;
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const getInitials = useMemo(() => {
    if (userFullName) {
      return getNameInitials(userFullName);
    }
  }, [userFullName]);

  return (
    // Header Start
    <HeaderComponent
      history={history}
      isLoggedin={isLoggedin}
      getInitials={getInitials}
      handleLogout={handleLogout}
      pathName={pathName}
    />
    // Header End
  );
};
export default Header;
