import { authInstance } from "../services/api";
import {
  ILoginFormValues,
  ISignupFormValues,
  ISocialLoginValues,
} from "../utils/Interfaces/authInterfaces";

export const userLogin = async (loginData: ILoginFormValues) => {
  const response: any = await authInstance.post<ILoginFormValues>(
    "/v1/user/login",
    loginData
  );
  return response;
};

export const signUpRequest = async (signupData: ISignupFormValues) => {
  const response: any = await authInstance.post<ILoginFormValues>(
    "/v1/user/registration",
    signupData
  );
  return response;
};

export const forgotPasswordApi = async (email: string) => {
  const response: any = await authInstance.post<string>(
    "/v1/user/forgot-password",
    { email: email }
  );
  return response;
};

export const verifyLinkApi = async (token: string) => {
  const response: any = await authInstance.get<string>(
    `/v1/user/reset-password-verify/${token}`
  );
  return response;
};

export const resetPasswordApi = async (
  token: string,
  password: string | number
) => {
  const response: any = await authInstance.post<string>(
    `/v1/user/reset-password/${token}`,
    { password: password }
  );
  return response;
};
export const socialLoginApi = async (socialdata: ISocialLoginValues) => {
  const { email, name } = socialdata;
  const response: any = await authInstance.post<string>(
    `/v1/user/social-login`,
    { email: email, name: name }
  );
  return response;
};

export const verifyEmailApi = async (token: string) => {
  const response: any = await authInstance.get<string>(
    `/v1/user/verify-user/${token}`
  );
  return response;
};
