/* Array Helper functions */
export const addArrayToCache = (
  cache: {},
  arr: any[],
  key: string | undefined = "id"
) => {
  const newCache: { [id: string]: any } = { ...cache };
  arr.forEach((item: any) => {
    newCache[item[key]] = item;
  });
  return newCache;
};
export const addArrayToMap = (
  cache: Map<string, any>,
  arr: any[],
  key: string | undefined = "id"
) => {
  let newCache = cache;
  arr.forEach((item: any) => {
    newCache = newCache.set(item[key], item);
  });
  return newCache;
};

export const replaceOrAdd = (
  arr: any[],
  item: any,
  compare: (item: any) => boolean
) => {
  const index = arr.findIndex((item: any) => compare(item));

  if (index > -1) {
    const newArr = [...arr];
    newArr[index] = item;
    return newArr;
  } else {
    return [...arr, item];
  }
};
export const sortByCreatedAt = (arr: Array<any>) => {
  return arr.sort((a, b) =>
    b.createdAt && a.createdAt ? b.createdAt - a.createdAt : 0
  );
};
/* Array Helper functions */

/* Validations Helper Functions */
type PasswordType = {
  newPassword: string;
  confirmPassword: string;
  currentPassword: string;
};
export const validatePassword = (data: PasswordType) => {
  let errors: any;
  errors = {};
  if (data.hasOwnProperty("newPassword")) {
    if (data.newPassword === "") {
      errors.newPassword = "Enter a password";
    } else if (data.newPassword && data.newPassword.length < 6) {
      errors.newPassword = "Password should not be less than 6 characters";
    }
  }
  if (data.hasOwnProperty("confirmPassword")) {
    if (data.confirmPassword === "") {
      errors.confirmPassword = "Confirm your password";
    } else if (data.confirmPassword !== data.newPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
  }
  if (data.hasOwnProperty("currentPassword")) {
    if (data.currentPassword === "") {
      errors.currentPassword = "Enter current password";
    } else if (data.currentPassword && data.currentPassword.length < 6) {
      errors.currentPassword = "Password should not be less than 6 characters";
    }
  }
  return errors;
};

export const formatBytes = (bytes: number) => {
  let decimals = 2;
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const bytesToGB = (bytes: number) =>
  Math.max(0, bytes / Math.pow(10, 9));

export const nearest100 = (num: number) => num + (100 - (num % 100));
/* Validations Helper Functions */

export const dataURLtoFile = (dataurl: any, filename: string) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const getNameInitials = (name: string | any) => {
  var names = name.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
